/**
 * FLAG CONSTANTS
 *
 * Mapping of constant name to string flag name, for the purpose of IDE autocompletion
 *
 * When to use:
 *    If you are using the flag in a Dojo-specific context. A good litmus test is whether
 *    you will use this within the /web/js/mojo directory. If not, there's a good chance
 *    that you don't need to add your flag name here.
 *
 * Risks:
 *    These strings get sent in cleartext to the browser in an easily-discoverable format.
 *    Many flag names have team and feature names which can be sensitive information. Only
 *    add flag names you know you'll need.
 */
define([], function () {
  var keys = {
    FINOPS_USE_BANCONTACT_DANKORT_CHECKOUT: "finops.use_bancontact_dankort_checkout",
    FLP_EMAILLESS_CONTACTS: "flp.emailless_contacts",
    NEAPOLITAN_ONCHANGE_STYLE_INPUT: "neapolitan_onchange_style_input",
    LANDING_PAGES_BETA_BRANDED_BUNDLE: "landing_pages.beta_branded_bundle",
    ND_CIRCUIT_BREAKER_DISCONNECT: "nd.circuit_breaker.disconnect",
    AM_ORGANIZE_SELECTED_CONTACTS_COUNT: "am.organize.selected_contacts_count",
    AM_ORGANIZE_CAMPAIGN_FOLDERS_PER_AUDIENCE: "am.organize.campaign_folders_per_audience",
    RECESS_MULTILEVEL_CAMPAIGNS: "recess.multilevel_campaigns",
    SEGMENTATION_CONTACT_PROFILE: "segmentation.contact_profile",
    CONTACT_BILLING_MODAL_DESTROY_DESCENDANTS: "contact_billing_modal_destroy_descendants",
    EDITING_PLATFORM_LANDING_PAGE_ALPHA: "editing_platform.landing_page_alpha",
    MONETIZATION_PAUSE_PROMO_RESURFACING_EXPERIMENT: "monetization.pause_promo_resurfacing_experiment",
    FORCE_CHATS_OFFLINE: "force_chats_offline",
    FINOPS_PLAN_CHANGE_TIER_REFACTOR: "finops.plan_change_tier_refactor",
    FINOPS_FONOA_STATES_IN_INDIA_DROPDOWN: "finops.fonoa_states_in_india_dropdown",
    FINOPS_FONOA_STATES_IN_UAE_DROPDOWN: "finops.fonoa_states_in_uae_dropdown",
    FINOPS_REACT_BILLING_INFO: "finops.react_billing_info",
    FINOPS_CLEAR_CHECKOUT_TAX_ID: "finops.clear_checkout_tax_id",
    FINOPS_INCLUDE_MASTERCARD_COBRAND_ADYEN: "finops.include_mastercard_cobrand_adyen",
    MCMN_NGP_WALLET_INTEGRATION: "mcmn.ngp_wallet_integration",
    REPORTING_COMBINE_CLICKS: "reporting_combine_clicks",
    CEC_SLASH_SUPPORT_LOCALE_DROPDOWN_FIX: "cec.slash_support_locale_dropdown_fix",
    PIPE_REMOVE_LOOKALIKE: "pipe.remove_lookalike",
    UPG_MARTA_CREATE_TEMPLATE_UPGRADES: "upg.marta_create_template_upgrades",
    UPG_MARTA_SCHEDULE_CAMPAIGN: "upg.marta_schedule_campaign",
    REPORTING_DOWNLOAD_LINK_ATTRIBUTE: "reporting.download_link_attribute",
    PX_PROMOTE_FREE_TRIALS_VARIANT: "px.promote_free_trials_variant",
    WEF_LEFT_NAV_V2: "wef.left_nav_v2",
    NUA_INTEGRATION_DISCOVERY_EXPERIENCE: "nua.integration_discovery_experience",
    NUA_MIGRATED_INTEGRATION_HYDRATED_FROM_CONTENTFUL: "nua.migrated_integration_hydrated_from_contentful",
    PLUMS_INTEGRATION_DETAIL_MIGRATION: "plums.integration_detail_migration",
    PLUMS_INTEGRATION_FROM_CONTENTFUL: "plums.integration_from_contentful",
    PLUMS_MIGRATED_INTEGRATION_HYDRATED_FROM_CONTENTFUL: "plums.migrated_integration_hydrated_from_contentful",
    REACT_TO_PLUMS_MIGRATION: "plums.react_to_plums_migration",
    PLUMS_INTEGRATIONS_FROM_ROUTE: "plums.integrations_from_route",
    PLUMS_REACT_TO_PLUMS_HEADER_FOOTER_MIGRATION: "plums.react_to_plums_header_footer_migration",
    PLUMS_PREVENT_BETA_APP_CHECK: "plums.prevent_beta_app_check",
    PLUMS_ECS_TRACKING_PLUMS_TEMPLATE: "plums.ecs_tracking_plums_template",
    PLUMS_INTEGRATION_DOTCOM_ROLLBACK_STATIC_TRANSLATIONS: "plums.integration_dotcom_rollback_static_translations",
    PARTNERSHIPS_WIX_IDX_INTEGRATION: "partnerships.wix_idx_integration",
    PARTNERSHIPS_WIX_IDX_PAINTEDDOOR: "partnerships.wix_idx_painteddoor",
    MVPS_CANVA_PCD_PAGE: "mvps.canva_pcd_page",
    PARTNERSHIPS_WIX_IDX_PAINTEDDOOR_OVERRIDE: "partnerships.wix_idx_painteddoor_override",
    PLUMS_WIX_IDX_INTEGRATION: "plums.wix_idx_integration",
    WEF_CAMPAIGN_L0_INLINE_RECOMMENDATION: "wef.campaign_l0_inline_recommendation",
    HA_MCADMIN_PERMISSIONS_APPROVAL: "ha.mcadmin_permissions_approval",
    WEF_SMARTICLES_VARIABLE_GROUP: "wef.smarticles_variable_group",
    WEF_CONTACT_RESULTS_ALL_STATUSES: "wef.contact_results_all_statuses",
    WEF_LEFT_NAV_DISCOVERY: "wef.left_nav_discovery",
    PSIENGAGE_MANAGE: "psiengage.manage",
    EEE_BACKFILL_STRINGS_NUNI: "eee.backfill_strings_nuni",
    PARTNERPAGE_ECOGROWTH: "partnerpage.ecogrowth",
    APP_GOOGLE_ANALYTICS_4_SIGNUP: "app.google_analytics_4_signup",
    PARTNERPAGE_INTEGRATION: "partnerpage.integration",
    I18N_AUDIENCE_MANAGEMENT: "i18n.audience_management",
    I18N_ACCOUNT_MANAGEMENT_M2: "i18n.account_management_m2",
    I18N_PX_IN_APP: "i18n.px_in_app",
    I18N_HOMEPAGE: "i18n.homepage",
    I18N_NUNI: "i18n.nuni",
    I18N_LANDING_PAGES: "i18n.landing_pages",
    I18N_BILLING: "i18n.billing",
    I18N_REPORTING_TRANSLATIONS: "i18n.reporting_translations",
    I18N_REPORTING_TRANSLATIONS_V2: "i18n.reporting_translations_v2",
    I18N_CONTENT_OPTIMIZER: "i18n.content_optimizer",
    I18N_FORMS_FORM_BUILDER: "i18n.forms_form_builder",
    I18N_FORMS_EMBEDDED_FORMS: "i18n.forms_embedded_forms",
    I18N_FORMS_POP_UP: "i18n.forms_pop_up",
    I18N_FORMS_INTEGRATIONS: "i18n.forms_integrations",
    I18N_PAYMENT_ERROR_TRANSLATION: "i18n.payment_error_translation",
    I18N_PAGE_TITLES: "i18n.page_titles",
    I18N_FOOTERS: "i18n.footers",
    I18N_TRX_EMAILS: "i18n.trx_emails",
    I18N_TRX_EMAILS_VISITOR: "i18n.trx_emails_visitor",
    I18N_TRX_EMAILS_RANDOM: "i18n.trx_emails_random",
    I18N_DATE_JOURNEYS: "i18n.date.journeys",
    I18N_DATE_CAMPAIGNS: "i18n.date.campaigns",
    I18N_DATE_REPORTS: "i18n.date.reports",
    I18N_USE_CALLBACK_USE_LOCALIZE_DATE: "i18n.use_callback_use_localize_date",
    I18N_USE_CALLBACK_USE_FORMAT_NUMBER: "i18n.use_callback_use_format_number",
    I18N_IHOP_WEEK_1: "i18n.ihop.week_1",
    I18N_IHOP_WEEK_2: "i18n.ihop.week_2",
    I18N_IHOP_WEEK_3: "i18n.ihop.week_3",
    I18N_IHOP_WEEK_4: "i18n.ihop.week_4",
    I18N_LOGIN_INVITATION_EMAIL: "i18n.login_invitation_email",
    I18N_ACH_ITALY: "i18n.ach_italy",
    I18N_ACH_PIFFS: "i18n.ach_piffs",
    WEF_REWORK_CAMPAIGNS_SEARCH_BOX: "wef.rework_campaigns_search_box",
    HA_TRACK_MODAL_OPEN: "ha.track_modal_open",
    HA_HIDE_SMS_CHECKBOX: "ha.hide_sms_checkbox",
    PIE_DISCONNECT_REDIRECT: "pie.disconnect_redirect",
    PIE_RECOMMENDATIONS_CHECKLIST_V2: "pie.recommendations_checklist_v2",
    PIE_UPDATE_SHOPIFY_BUTTON: "pie.update_shopify_button",
    HA_RIGHT_RAIL_ENHANCED: "ha.right_rail_enhanced",
    HA_SEND_LOGS_ON_LAUNCH_OF_VEP: "ha.send_logs_on_launch_of_vep",
    ACP_USE_I18N_TRANSLATIONS: "acp.use_i18n_translations",
    ACP_ALL_CAMPAIGNS_SMS_CLICK_DELIVERY_STAT: "acp.all_campaigns_sms_click_delivery_stat",
    I18N_AUDIENCE_MANAGEMENT_V2: "i18n.audience_management_v2",
    ACP_I18N_SOCIAL_POST_CONFIRM: "acp.i18n.social_post_confirm",
    SMS_CAMPAIGN_SETUP: "sms.campaign_setup",
    SMS_ACTIVATE_MCADMIN_SMS_UPDATES: "sms.activate_mcadmin_sms_updates",
    HA_CR2_ENTRY_POINTS: "ha.cr2_entry_points",
    HA_IMPORT_EP_BYPASS_ARRAY: "ha.import_ep_bypass_array",
    SEGMENTATION_CUB_I18N: "segmentation.cub_i18n",
    SEGMENTATION_LEGACY_BUILDER_I18N: "segmentation.legacy_builder_i18n",
    SMS_DEMO_EDITOR: "sms.demo_editor",
    SMS_NONSUBSCRIBE: "sms.nonsubscribe",
    SMS_SUBSCRIPTION_SERVICE: "sms.subscription_service",
    SEGMENTATION_LEGACY_CAMPAIGNS: "segmentation.aim_campaigns_as_objects",
    HA_UNAUTHENTICATED_DOMAIN_TAG: "ha.unauthenticated_domain_tag",
    MCSC_LAU_SUPPORT_MENU: "mcsc.lau_support_menu",
    EEE3_EMAIL_CHECKLIST_CJB_TRIGGERS: "eee3.email_checklist_cjb_triggers",
    EEE3_REBRAND_TWITTER_X_EMAIL_CHECKLIST: "eee3.rebrand_twitter_x_email_checklist",
    PLAN_CRM_WRAPPER: "plan.crm_wrapper",
    HOP_UNDEFINED_MESSAGE_CONTENT_MANAGER: "hop.undefined_message_content_manager",
    PIE_MANAGE_CHUNK_ERRORS: "pie.manage_chunk_errors",
    SEGMENTATION_FIX_FOR_BETWEEN_DATE: "segmentation.fix_for_between_date",
    SEGMENTATION_APPLE_MPP: "segmentation.apple_mpp",
    CUSTOMER_JOURNEY_ACCESS_POINTS: "customer_journey_access_points",
    EEE3_REBRAND_TWITTER_X_SOCIAL_POST: "eee3.rebrand_twitter_x_social_post",
    WAYFI_SEARCH_ECS_TRACKING: "wayfi.search_ecs_tracking",
    PIE_WIX_ALERT: "pie.wix_alert",
    ACP_ALL_CAMPAIGNS_SLAT_TRANSLATION: "acp.all_campaigns_slat_translation",
    SEGMENTATION_ESCAPED_TAGS_LEGACY_ADVANCED_BUILDER_FIX: "segmentation.escaped_tags_legacy_advanced_builder_fix",
    ELC_RELEASE_1_SMS_COHORT: "has_elc_release_1_sms_cohort_ff",
    FINOPS_USE_LE_MIDS_FOR_AMEX: "finops.use_le_mids_for_amex",
    FINOPS_ALLOW_NEGATIVE_CREDITS: "finops.allow_negative_credits",
    PIE_WOOCOMMERCE_MVP: "pie.woocommerce_mvp",
    PIE_BIG_COMMERCE_MVP: "pie.big_commerce_mvp",
    ECP_BIG_CARTEL_MVP: "ecp.big_cartel_mvp",
    ECP_SHOW_DANGLING_API_KEYS: "ecp.show_dangling_api_keys",
    ECP_CUSTOMER_INTERVIEW_BANNER: "ecp.customer_interview_banner",
    ECP_CUSTOM_ATTRIBUTION_WINDOWS_2: "ecp.custom_attribution_windows_2",
    GEN_CAMPAIGN_CREATED_ENGAGED_GEN_TAB_TRACKING: "gen.campaign_created_engaged_gen_tab_tracking",
    EEE_UPDATE_SOCIALS_ICONS: "eee.update_socials_icons",
    EEE3_NO_AUTH_NO_ENTRY_GLOBAL_MUNGING_MESSAGES: "eee3.no_auth_no_entry_global_munging_messages",
    EEE_NEA_GENAI_WRITE_EDIT_BUTTON_BUGFIX: "eee.nea_genai_write_edit_button_bugfix",
    EEE_NEA_GENERATE_TEXT_CTA_UI_BUGFIX: "eee.nea_generate_text_cta_ui_bugfix",
    EEE_NEW_NEA_SOCIALS_FOLLOW: "eee.new_nea_socials_follow",
    EMAILIN_FIX_INBOX_PREVIEW_CLIENTS_LINK: "emailin.fix_inbox_preview_clients_link",
    ECP_WIX_CONNECT_CLICK: "ecp.wix_connect_click",
    ECP_KLAVIYO_INTEGRATION: "ecp.klaviyo_integration",
    EM2_NEA_GENAI_GRAMMAR_SPELLING_SHORTCUT_TRACKING: "em2.nea_genai_grammar_spelling_shortcut_tracking",
    I18N_DATE_ACCOUNT_SETTINGS: "i18n.date.account_settings",
    SMS_PREVENT_EDIT_TERMS: "sms.prevent_edit_terms",
    EM2_UPDATE_CONTENT_GET_INFO_RATE_LIMITING: "em2.update_content_get_info_rate_limiting",
    TPPS_INTEGRATION_CONNECT_REFACTOR: "tpps.integration_connect_refactor",
    MVPS_CANVA_MYFILES_TAB: "mvps.canva_myfiles_tab",
    YOUTUBE_REQUEST_IMPROVEMENTS: "youtube_request_improvements",
    EM2_NEA_COMMENTS_ECS_TRACKING: "em2.nea_comments_ecs_tracking",
    EM2_NEA_TO_NUNI_AWARENESS_EXPERIMENT: "em2.nea_to_nuni_awareness_experiment",
    EM2_NEA_NUNI_EDITOR_SETTINGS_ECS: "em2.nea_nuni_editor_settings_ecs",
    PLAN_GENAI_ADDON_EXPERIMENT: "plan.genai_addon_experiment",
    PULSE_SUNSET_POSTCARDS_REPORTS: "pulse.sunset_postcards_reports",
    M10N_ADMIN_AGENTS_TO_ADD_MORE_THAN_ONE_DISCOUNT: "m10n.admin_agents_to_add_more_than_one_discount",
    EMAILIN_FIX_SOCIAL_CARDS_XSS_VULNERABILITY: "emailin.fix_social_cards_xss_vulnerability",
    FINOPS_THREEDS_BUG_IN_BILLINGINFO: "finops.threeds_bug_in_billinginfo",
    PLAN_IN_APP_INTRO_PRICING_EXPERIMENT: "plan.in_app_intro_pricing_experiment",
    MVPS_DISABLE_TWITTER: "mvps.disable_twitter",
    PLAN_SUPPRESS_PROMOTIONS_FREE_TRIAL_AND_50_PERCENT: "plan.suppress_promotions_free_trial_and_50_percent",
    FINOPS_USER_CAN_NOT_SAVE_PAYMENT_METHOD: "finops.user_can_not_save_payment_method",
    IDENTITY_GOOGLE_SSO_EXPERIMENT: "identity.google_sso_experiment",
    REPORTING_INDIVIDUAL_EMAIL_REPORT_REACT_MIGRATION: "reporting.individual_email_report_react_migration",
    FINOPS_HIDE_TAX_ID: "finops.hide_tax_id",
    PLAN_USE_PUB_DATA_PROVIDER_LEFT_NAVIGATION_NUNI_TEMPLATE: "plan.use_pub_data_provider_left_navigation_nuni_template",
    PLAN_USE_USER_DATA_PROVIDER_LEFT_NAVIGATION_NUNI_TEMPLATE: "plan.use_user_data_provider_left_navigation_nuni_template",
    PLAN_GEN_AI_PURCHASE_INTENT_EXPERIMENT_TRAFFIC: "plan.gen_ai_purchase_intent_experiment_traffic",
    PLAN_SHORTENED_TRIALS_EXPERIMENT: "plan.shortened_trials_experiment",
    IDENTITY_MC_GSSO_LOGIN_FLOW_DOTCOM_INTEGRATION: "identity.mc_gsso_login_flow_dotcom_integration",
    PLAN_HIDE_PRICING_PLANS_IN_ACCOUNT_DECLINE_STATE: "plan.hide_pricing_plans_in_account_decline_state",
    CJB_PHP_UPGRADE_BUG_FIXES: "cjb_php_upgrade_bug_fixes"
  };
  return keys;
});